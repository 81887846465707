import React, { useRef } from 'react';
import InputMask from 'react-input-mask';
import Styles from './styles.module.scss';
import { isDateValid } from '../../utils';

const DatePickerMask = React.forwardRef((props, ref) => {
	const {
		isinvalid,
		onClick,
		id,
		showtimeselectonly = false,
		disabled,
		arialabel,
		onChange,
		mindate,
		maxdate,
		dateformat,
		rendericon = 'false'
	} = props;
	const textRendering = {
		textAlign: 'left'
	};
	const defaultOnChangeRef = useRef(onChange);

	const handleInputChange = (e) => {
		if (dateformat && dateformat.toString().toLowerCase() === 'mm/dd/yyyy') {
			if (e.target.value === '__/__/____') {
				e.target.value = null;
			}
		}

		defaultOnChangeRef.current(e);
	};

	return (
		<div className="position-relative custom-input-content" style={{ minWidth: '130px' }}>
			{dateformat && dateformat.toString().toLowerCase() === 'mm/yyyy' && (
				<InputMask
					mask="99/9999"
					aria-label={arialabel}
					ref={ref}
					{...props}
					data-testid={`datePicker-${id}`}
					style={props.minTime !== null ? textRendering : ''}
					onChange={handleInputChange}
				/>
			)}
			{dateformat && !['mm/dd/yyyy', 'mm/yyyy'].includes(dateformat.toString().toLowerCase()) && (
				<input
					aria-label={arialabel}
					ref={ref}
					{...props}
					data-testid={`datePicker-${id}`}
					style={props.minTime !== null ? textRendering : ''}
				/>
			)}
			{dateformat && dateformat.toString().toLowerCase() === 'mm/dd/yyyy' && (
				<InputMask
					mask="99/99/9999"
					aria-label={arialabel}
					ref={ref}
					{...props}
					data-testid={`datePicker-${id}`}
					style={props.minTime !== null ? textRendering : ''}
					onChange={handleInputChange}
				/>
			)}
			{rendericon === 'true' && isinvalid !== 'true' && !disabled && (
				<i
					className={`mx-1 ${showtimeselectonly === 'true' ? 'far fa-clock' : 'far fa-calendar'} ${
						Styles.NewDatePickerStyle
					}`}
					onClick={onClick}
				/>
			)}
		</div>
	);
});

export default DatePickerMask;
