import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ErrorMessage, useField } from 'formik';
import { isMobile } from 'react-device-detect';

import styles from './styles.module.scss';
import { formatDate, dateStringToYYMMDD } from '../../../utils';
import AffectsReportAlertTooltip from '../../../components/AffectsReportAlertTooltip/AffectsReportAlertTooltip';
import { RequiredMark } from '../../../components/common';
import { addYears } from 'date-fns';
import DatePickerMask from '../../../components/DatePickerMask';

export const FormatDatePicker = ({
	onFocus = () => {},
	maxDate = addYears(new Date(), 100),
	minDate = new Date(1900, 0, 1),
	arialabel,
	...props
}) => {
	const [field, meta] = useField(props);
	const formValueRef = useRef('');
	const [value, setValue] = useState('');
	const datePickerRef = useRef(null);

	const formik = props.formik;
	const setDate = field.value;
	const setDefault = props.defaultValue;

	const handleGeneralChange = (e) => {
		if (e) {
			if (!setDefault) props.functionDate(e);
			setValue(e);
			handleError(e);
		} else {
			props.functionDate('');
			formValueRef.current = '';
		}
		setTimeout(() => {
			datePickerRef.current.setFocus();
		}, 0);
	};

	const handleError = (e) => {
		formValueRef.current = dateStringToYYMMDD(e);
		formik.setFieldTouched(props.id, true);
		formik.setFieldValue(props.id, formValueRef.current, true);
	};

	const datePickerFormat = (date) => {
		if (!date) {
			return '';
		}
		date = new Date(formatDate(date));
		return date;
	};
	const fetchData = useCallback(() => {
		if (setDefault) {
			setValue(datePickerFormat(setDefault));
		} else {
			if (setDate) {
				setValue(datePickerFormat(setDate));
			} else {
				setValue('');
				handleError('');
			}
		}
	}, [setDate]);

	useEffect(() => {
		fetchData(); // eslint-disable-line react-hooks/exhaustive-deps
	}, [fetchData]);

	const focusInput = () => {
		if (props.disabled) return;
		datePickerRef.current.onInputClick();
		onFocus();
	};

	const onKeyDown = (e) => {
		if (props.preventOpenOnFocus) {
			if (e.key === 'Enter' && !datePickerRef.current.state.open) {
				e.preventDefault();
				datePickerRef.current?.setOpen(true);
			}
		}
	};

	const handleOnFocus = (event) => {
		if (isMobile) {
			event.target.blur();
		}
		onFocus(event);
	};
	useEffect(() => {
		if (props.isAutoDate) {
			setValue(datePickerFormat(new Date()));
			handleError(new Date());
		}
	}, []);

	return (
		<>
			<AffectsReportAlertTooltip isReportAffected={props.isReportAffected}>
				<label htmlFor={props.id} className="form-label">
					{props.label}
					{props.required && <RequiredMark />}
					{props.isReportAffected && (
						// The period is required to pause the screenreader to read the tooltip
						<span className="visually-hidden">. {props.isReportAffected.tooltip}</span>
					)}
				</label>
			</AffectsReportAlertTooltip>
			<div className={styles.datePickerWrapper}>
				<DatePicker
					shouldCloseOnSelect={true}
					placeholderText={'mm/dd/yyyy'}
					dateFormat="MM/dd/yyyy"
					selected={value || null}
					peekNextMonth
					showMonthDropdown
					showYearDropdown
					onBlur={(e) => {
						if (e && (new Date(e.target?.value) > maxDate || new Date(e.target?.value) < minDate)) {
							formik.setFieldValue(props.id, e.target?.value);
						}
					}}
					dropdownMode="select"
					onChange={handleGeneralChange}
					className={`form-control ${meta.touched && meta.error && 'is-invalid'} ${
						props.disabled ? 'notAllowed' : ''
					}`}
					name={props.name}
					id={props.id}
					minDate={minDate}
					maxDate={maxDate}
					disabled={props.disabled}
					autoComplete="off"
					onKeyDown={onKeyDown}
					preventOpenOnFocus={props.preventOpenOnFocus}
					ref={datePickerRef}
					onFocus={handleOnFocus}
					customInput={
						<DatePickerMask
							ref={datePickerRef}
							arialabel={arialabel}
							mindate={minDate}
							maxdate={maxDate}
							rendericon={'false'}
							dateformat={'MM/dd/yyyy'}
						/>
					}
				/>
				<i className={`far fa-calendar`} onClick={focusInput}></i>
			</div>

			<ErrorMessage
				component="div"
				name={field.name}
				className={'small text-danger'}
				role="alert"
			/>
		</>
	);
};
